import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-demo-video',
  templateUrl: './demo-video.page.html',
  styleUrls: ['./demo-video.page.scss'],
})
export class DemoVideoPage implements OnInit {
  @Input() videoPartNo: string;

  videoTypeNo : any;

  constructor(
    public navCtrl: NavController,
    public modalCtrl :ModalController,
    public navParams: NavParams
  ) { 
    this.videoTypeNo = this.navParams.get('videoPartNo');
  }

  ngOnInit() {

  }

  closePage() {
    this.modalCtrl.dismiss();
  }

}
