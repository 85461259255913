import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { ApiService } from '../api.service';

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.page.html',
  styleUrls: ['./tracking-details.page.scss'],
})
export class TrackingDetailsPage implements OnInit {
  @Input() trackDetailsData: string;
  
  trackData:any;
  trackingDetails = [];
  constructor(
    private api: ApiService,
    public navCtrl: NavController,
    public modalCtrl :ModalController,
    public navParams: NavParams
  ) {
    this.trackData = navParams.get('trackDetailsData');
   }

  ngOnInit() {
    this.api.showLoading();
    this.api.getTrackingDetails(this.trackData).subscribe((data:any) => {
      this.api.hideLoading();
      if(data && data.data){
        this.trackingDetails = (data.data.origin_info.trackinfo?data.data.origin_info.trackinfo:[])
      }
    },error => {
      this.api.hideLoading();
      this.api.alertFunCall('Warning!','','Please try again','OK');
    })
  }

  closePage() {
    this.modalCtrl.dismiss();
  }

}
