import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.page.html',
  styleUrls: ['./user-details.page.scss'],
})
export class UserDetailsPage implements OnInit {
  @Input() userDetailsData: string;

  
  userData:any;
  constructor(
    public navCtrl: NavController,
    public modalCtrl :ModalController,
    public navParams: NavParams
  ) {
    this.userData = navParams.get('userDetailsData');
  }

  ngOnInit() {
  }
  
  closePage() {
    this.modalCtrl.dismiss();
  }
  
}
